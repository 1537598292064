import(/* webpackMode: "eager" */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
import(/* webpackMode: "eager" */ "/app/src/app/globals.scss");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"roboto\"}");
import(/* webpackMode: "eager" */ "/app/src/base/hoc/runtime-env/env-container.tsx");
import(/* webpackMode: "eager" */ "/app/src/base/lib/AntdRegistry.tsx");
import(/* webpackMode: "eager" */ "/app/src/base/provider/auth/index.tsx");
import(/* webpackMode: "eager" */ "/app/src/base/state/index.tsx")