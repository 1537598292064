"use client";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { middlewares as apiMiddlewares, reducers as apiReducers } from "./api";
import { reducers as sliceReducers } from "./slice";

const combineReducer = combineReducers({
  ...(sliceReducers || {}),
  ...(apiReducers || {}),
});

export const store = configureStore({
  reducer: combineReducer,
  middleware: (getDefaultMiddleware) => {
    let defaultMiddleware = [...getDefaultMiddleware()];
    Object.values(apiMiddlewares).forEach((item: any) => {
      defaultMiddleware = defaultMiddleware.concat(item);
    });
    return defaultMiddleware;
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const GlobalStateProvider = ({ children }: any) => {
  return <Provider store={store}>{children}</Provider>;
};
