"use client";

import { useAuth } from "@/base/hook/auth";

const AuthProvider = ({ children }: React.PropsWithChildren) => {
  useAuth({ shouldRefreshTokenIfNeeded: true });

  return <>{children}</>;
};

export default AuthProvider;
