"use client";
import {
  getStatefulApiHeaders,
  setStatefulApiHeaders,
  useStatefulApi,
} from "@/base/state/api";
import { GlobalApiHook, GlobalApiHookOptions } from "@/base/state/api.type";
import { useAuth } from "../auth";
import { ACCESS_TOKEN } from "@/base/constant/auth";
import { isClientSide } from "@ant-design/cssinjs/lib/util";
import { useEffect, useMemo } from "react";

export const useAuthorizedStatefulApi = <
  B extends any,
  D extends any,
  E extends any
>(
  path: string,
  options?: GlobalApiHookOptions<B, D>
): GlobalApiHook<B, D, E> => {
  const { handle401Error } = useAuth();
  const headerAuthKey = "authorization";
  const headers = getStatefulApiHeaders();
  if (!headers?.[headerAuthKey]) {
    const accessToken = isClientSide
      ? localStorage.getItem(ACCESS_TOKEN)
      : null;

    if (accessToken) {
      setStatefulApiHeaders({
        [headerAuthKey]: `Bearer ${accessToken}`,
      });
    }
  }
  const res = useStatefulApi<B, D, E>(path, options);
  const { error, retryRequestOnFailed }: any = useMemo(() => res || {}, [res]);

  useEffect(() => {
    if (error?.status !== 401) return;
    handle401Error(retryRequestOnFailed);
  }, [error?.status, handle401Error, retryRequestOnFailed]);

  return res;
};

export const useUnathorizedStatefulApi = <
  B extends any,
  D extends any,
  E extends any
>(
  path: string,
  options?: GlobalApiHookOptions<B, D>
): GlobalApiHook<B, D, E> => {
  return useStatefulApi<B, D, E>(path, options);
};
