import { AuthHook } from "./index.type";
import { usePathname, useRouter } from "next/navigation";
import { useCallback, useEffect, useRef, useState } from "react";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import paths from "./path/index.json";
import { useUnathorizedStatefulApi } from "../stateful-api";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "@/base/constant/auth";
import { isClientSide } from "@ant-design/cssinjs/lib/util";
import { GlobalApiRequestFunc } from "@/base/state/api.type";
import { setStatefulApiHeaders } from "@/base/state/api";
import { useGlobalState } from "@/base/state/slice";

const DEFAULT_AUTHORIZED_PATH = "/dashboard"; // TODO move to path/index.json file
const headerAuthKey = "authorization";

export const useAuth: AuthHook = (options) => {
  const pathname = usePathname();
  const router = useRouter();
  const { data: { data: newTokens } = {}, request: refreshNewToken } =
    useUnathorizedStatefulApi<any, any, any>("auth.refreshToken");

  const [skipGetMe, setSkipGetMe] = useState(
    options?.defaultSkipGetMe ?? false
  );
  const { data: { data: userRole } = {} as any }: any =
    useUnathorizedStatefulApi<any, any, any>("user.getMe", {
      skip: skipGetMe,
    });

  const { update: setUserRole } = useGlobalState("user.role");

  const refetchRef = useRef<GlobalApiRequestFunc<any> | null>();

  const redirectToLoginPage = useCallback(() => {
    const path = Object.keys(paths.defaultUnauthorizedPath || {})[0];
    if (path && pathname !== path) {
      router.push(path);
    }
  }, [pathname, router]);

  const redirectToDefaultPage = useCallback(() => {
    if (pathname !== DEFAULT_AUTHORIZED_PATH) {
      router.push(DEFAULT_AUTHORIZED_PATH);
    }
  }, [pathname, router]);

  const isPathIncluded = useCallback(
    (paths: string[]) => {
      if (paths.includes(pathname)) return true;

      const pathnameParts = pathname.split("/");
      const wildcardPaths = paths.filter((path) => path.includes("*"));

      if (!wildcardPaths?.length) return false;

      return wildcardPaths.some((path) => {
        const parts = path.split("/");
        parts.forEach((part, i) => {
          if (part !== "*") return;
          parts[i] = pathnameParts[i] ?? "_";
        });
        return pathname.includes(parts.join("/"));
      });
    },
    [pathname]
  );

  const isTokenExpired = useCallback((token: string) => {
    const decoded = jwtDecode<any>(token);
    const expirationTime = dayjs.unix(decoded.exp);
    const currentTime = dayjs();

    // check if token is expired within 10 minutes
    const timeDifference = expirationTime.diff(currentTime, "seconds");

    return timeDifference <= 0;
  }, []);

  const logout = useCallback(() => {
    isClientSide && localStorage.removeItem(ACCESS_TOKEN);
    isClientSide && localStorage.removeItem(REFRESH_TOKEN);
    redirectToLoginPage();
  }, [redirectToLoginPage]);

  const doRefreshToken = useCallback(
    (payload: any, request?: () => void) => {
      refetchRef.current = request;
      refreshNewToken(payload);
    },
    [refreshNewToken]
  );

  const handle401Error = useCallback(
    (request: () => void) => {
      const refreshToken = isClientSide
        ? localStorage.getItem(REFRESH_TOKEN)
        : null;

      if (!refreshToken || isTokenExpired(refreshToken)) {
        logout();
      }

      doRefreshToken(
        {
          body: {
            refreshToken,
          },
        },
        request
      );
    },
    [isTokenExpired, doRefreshToken, logout]
  );

  const setTokens = useCallback(
    (data: { refresh_token: string; access_token: string }) => {
      if (data?.access_token && isClientSide) {
        localStorage.setItem(ACCESS_TOKEN, data.access_token);
        setStatefulApiHeaders({
          [headerAuthKey]: `Bearer ${data?.access_token}`,
        });
        setSkipGetMe(false);
      }
      if (data?.refresh_token && isClientSide)
        localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
    },
    []
  );

  // update new tokens
  useEffect(() => {
    if (!newTokens?.access_token || !newTokens?.refresh_token) {
      return;
    }
    setStatefulApiHeaders({
      [headerAuthKey]: `Bearer ${newTokens?.access_token}`,
    });
    setTokens(newTokens);
    refetchRef.current?.();
    refetchRef.current = null;
  }, [newTokens, setTokens, refetchRef]);

  // refresh token if access token is expired
  useEffect(() => {
    if (!isClientSide) return;
    if (!options?.shouldRefreshTokenIfNeeded) return;

    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (!refreshToken) return;

    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
      const isExpired = isTokenExpired(accessToken);
      if (!isExpired) return;
    }

    doRefreshToken({
      body: {
        refreshToken,
      },
    });
  }, [doRefreshToken, isTokenExpired, options?.shouldRefreshTokenIfNeeded]);

  // redirect to default page if not authorized
  useEffect(() => {
    if (!isClientSide) return;
    if (!options?.shouldRefreshTokenIfNeeded) return;

    const allAuthorizedPaths = Object.keys(paths.authorizedPaths || {});
    const allUnauthorizedPaths = Object.keys(paths.unauthorizedPaths || {});
    const allUnsetPaths = Object.keys(paths.unsetPaths || {});

    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    const isAuthenticated = refreshToken && !isTokenExpired(refreshToken);

    if (
      !isAuthenticated &&
      isPathIncluded(allUnsetPaths) &&
      isPathIncluded(allAuthorizedPaths) &&
      !isPathIncluded(allUnauthorizedPaths)
    ) {
      redirectToLoginPage();
    } else if (isAuthenticated && isPathIncluded(allUnsetPaths)) {
      redirectToDefaultPage();
    }
  }, [
    isPathIncluded,
    isTokenExpired,
    options?.shouldRefreshTokenIfNeeded,
    redirectToDefaultPage,
    redirectToLoginPage,
  ]);

  useEffect(() => {
    setUserRole(userRole?.role);
  }, [userRole, setUserRole]);

  return {
    redirectToDefaultPage,
    redirectToLoginPage,
    handle401Error,
    setTokens,
    logout,
  };
};
